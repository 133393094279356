import React, { Component } from 'react';
import './CV.scss';
import data from '../../../data/cv.json';

function makeExperienceItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.start} 
        {' '} to {' '}
        {item.end}
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold">{item.role}, {item.organization}</span>
        <span>{item.location}</span>
      </p>
    </li>
  );
}

function makeEducationItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.start} 
        {' '} to {' '}
        {item.end}
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold">{item.degree}</span>
        Specializations: {item.specializations}
        ,
        <span>{item.organization}, {item.location}</span>
      </p>
    </li>
  );
}

function makeBookItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.date} 
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold"><a className="link" href={item.url} target="_blank" noreferrer>{item.title}</a></span>
        <span>{item.authors}</span>
        <span>{item.publisher}</span>
      </p>
    </li>
  );
}

function makeTalkItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.date} 
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold"><a className="link" href={item.url} target="_blank" noreferrer>{item.title}</a></span>
        <span>{item.authors}</span>
        <span>{item.location}, {item.event}</span>
      </p>
    </li>
  );
}

function makeTeachingItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.date} 
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold"><a className="link" href={item.url} target="_blank" noreferrer>{item.title}</a></span>
        <span>{item.role}</span>
        <span>{item.program}, {item.location}</span>
      </p>
    </li>
  );
}

function makeAwardItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.date} 
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold"><a className="link" href={item.url} target="_blank" noreferrer>{item.title}</a></span>
        <span>{item.by}, {item.location}</span>
      </p>
    </li>
  );
}

function makeOpenSourceItem(item) {
  return (
    <li className="cv__list-item">
      <p className="cv__list-item-content--left">
        {item.date} 
      </p>
      <p className="cv__list-item-content--right">
        <span class="cv__list-item-content--bold"><a className="link" href={item.url} target="_blank" noreferrer>{item.title}</a></span>
        <span>{item.role}, {item.location}</span>
      </p>
    </li>
  );
}


class CV extends Component {
  render() {
    return (
      <div className="cv">
        <main className="main">
          <h1 className="main__title">Curriculum Vitae</h1>
          <div className="content">
            <h3 className="content__subtitle">Experience</h3>
            <ul class="cv__list">{data.experience.map(makeExperienceItem)}</ul>

            <h3 className="content__subtitle">Education</h3>
            <ul class="cv__list">{data.education.map(makeEducationItem)}</ul>

            <h3 className="content__subtitle">Books Authored</h3>
            <ul class="cv__list">{data.books.map(makeBookItem)}</ul>

            <h3 className="content__subtitle">Paper Publications</h3>
            <ul class="cv__list">{data.publications.map(makeBookItem)}</ul>
            
            <h3 className="content__subtitle">Talks</h3>
            <ul class="cv__list">{data.talks.map(makeTalkItem)}</ul>

            <h3 className="content__subtitle">Exhibitions</h3>
            <ul class="cv__list">{data.exhibitions.map(makeTalkItem)}</ul>

            <h3 className="content__subtitle">Teaching</h3>
            <ul class="cv__list">{data.teaching.map(makeTeachingItem)}</ul>

            <h3 className="content__subtitle">Workshops</h3>
            <ul class="cv__list">{data.workshops.map(makeTalkItem)}</ul>

            <h3 className="content__subtitle">Awards</h3>
            <ul class="cv__list">{data.awards.map(makeAwardItem)}</ul>

            <h3 className="content__subtitle">Opensource Contributions</h3>
            <ul class="cv__list">{data.opensource.map(makeOpenSourceItem)}</ul>
          </div>
        </main>
      </div>
    );
  }
}

export default CV;
