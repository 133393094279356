import React, { Component } from 'react';
import CV from "../CV/CV";
import './About.scss';


class About extends Component {
  render() {
    return (
      <div className="about">
        <header className="about__header">
          <h1 className="about__title">About</h1>
          <p className="about__text">
            I'm a creative technologist and software developer based in Brooklyn, New York.
          </p>
        </header>
        <main className="about__main">
          <section className="about__section">
            <h2 className="about__section__title">Experience</h2>
            <p className="about__section__text">
              I am <span>currently working as a software developer </span> 
              at The New York Times. I work with a talented team of product designers, 
              software engineers, project managers, and newsroom editors to build tools
              that help journalists bring their reporting to the public. Specifically, I
              work on building features that support and extend NYT's newsletter products
              and capabilites.
            </p>
            <p className="about__section__text">
              In my free time, I try my best to support lovely open source
              projects such as ml5.js and p5.js and maintain an arts practice that
              provides opportunities to collaborate with friends.
            </p>
            {/* <p className="about__section__text">
              I am <span>currently working as a software developer</span>,
              leading the design and development of ml5.js, an open source,
              web-based machine learning software for artists, designers,
              educators, and coders. I am supported as a{' '}
              <span>research fellow</span> and <span>faculty member</span> at
              NYU's Interactive Telecommunications Program / Interactive Media
              Arts in the Tisch School of Art.
            </p> */}
            <p className="about__section__text">
              {/* I've worked internationally in relevant contexts as an open source and fullstack
              developer, digital cartographer, product
              and user experience designer, community manager, research
              scientist, technical/science writer, editor, photographer, open
              science advocate, and professor/educator, to name a few. Some
              institutions I've worked with or collaborated with: */}
              I've worked internationally in roles focused on open source and
              fullstack software development, digital cartography, data analysis, and
              product and user experience design. In addition, many of my roles
              have involved community management, experimental research,
              technical/science writing, editing, photography, advocacy, and
              teaching, to name a few. Some institutions I've worked with or
              collaborated with:
            </p>
            <div className="about__section__lists">
              <ul className="about__section__list">
                <li className="about__section__list__item">
                  Mozilla Foundation
                </li>
                <li className="about__section__list__item">
                  Daimler AG / Mercedes Benz (moovel/moovel Lab → Move Lab,
                  Car2Go)
                </li>
                <li className="about__section__list__item">
                  Google Creative Lab
                </li>
                <li className="about__section__list__item">The World Bank</li>
                <li className="about__section__list__item">Kickstarter</li>
                <li className="about__section__list__item">The Coding Train</li>
                <li className="about__section__list__item">
                  Penguin Random House
                </li>
                <li className="about__section__list__item">
                  Princeton Architectural Press
                </li>
                <li className="about__section__list__item">
                  Verlag Hermann Schmidt
                </li>
                <li className="about__section__list__item">Transworld Media</li>
              </ul>
              <ul className="about__section__list">
                <li className="about__section__list__item">NYU</li>
                <li className="about__section__list__item">Carnegie Mellon</li>
                <li className="about__section__list__item">MIT</li>
                <li className="about__section__list__item">UBC</li>
                <li className="about__section__list__item">UCLA</li>
                <li className="about__section__list__item">
                  University of Freiburg
                </li>
              </ul>
            </div>
          </section>
          {/* <section className="about__section">
            <h2 className="about__section__title">Tools I use</h2>
            <p className="about__section__text">
              Tools come and go, but if you're curious and it is helpful for
              you, lately I happily work with modern web technologies and other
              media, such as those listed below:
            </p>
            <p className="about__section__text">
              <span>Front end</span>: HTML5 | Frameworks: Vue.js,
              React.js/Next.js, Choo.js, Webpack/Parcel, SCSS | Visualization:
              p5.js, d3.js, mapboxGL.js, leaflet.js | Data: ml5.js /
              tensorflow-js, turf.js | CMS: Gatsby.js, Jekyll
            </p>
            <p className="about__section__text">
              <span>Back-end</span>: Node.js/Express.js, MongoDB | Mapping &
              Data Science: Python, R, QGIS
            </p>
            <p className="about__section__text">
              <span>Hardware</span>: Arduino, Cameras, skateboards
            </p>
          </section>          */}
        </main>
      </div>
    );
  }
}

export default About;
